import React from "react";
import localize from "../components/_global/localization/localize";
import { graphql } from "gatsby";
import Layout from "../components/_global/layout/Layout";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Block from "../components/_global/editorial/Block";
import Spacer from "../components/_global/layout/Spacer";
import ImForm from "../components/_global/forms/IMForm";

const Newsletter = ({ data, ...props }) => {
		const { page, form } = data;

		return (
			<Layout title={page.common.title}>
				<PageHeader title={page.common.title} image={page.splashImage} />

				<Section m="large">
					<Block data={page.text} />
					<Spacer s="large" />

					<ImForm data={page.form} gen={form} />
				</Section>
				<Spacer s="large" />
				<Spacer s="large" />

				{/*<Section m="large">*/}
				{/*	<ImNewsletter />*/}
				{/*	/!*<Form data={nlData.form} gen={formGenData} action={subscribe} message={subscriptionMessages[subscribed]} />*!/*/}
				{/*</Section>*/}
			</Layout>
		);
	}
;

export default localize(Newsletter);

export const query = graphql`
    {
        form: sanityFormComponent
        {
            ...
            FormComponentFragment
        }
        page: sanityNewsletter
        {
            common
            {
                link
                title
                {
                    _type
                    fr
                }
            }
            splashImage
            {
                ...
                ImageFragment
            }
            text
            {
                ...
                RegularBlockFragment
            }
            form
            {
                ...
                FormFragment
            }
        }
    }
`;
